import { catchError, map, Observable, of, retry, throwError } from 'rxjs';

export const errorHandling = <T>(errorEmitter: (error: string) => void, defaultErrorMessage: string='', defaultResponse: T) =>
  (source: Observable<any>) =>
    source.pipe(
      retry({
        count: 2,
        delay: 3000
      }),
      catchError(() => {
        errorEmitter(defaultErrorMessage);
        return of<T>(defaultResponse)
      }),
      map((res: any) => {
        return res.hasError ? throwError(() => new Error(res.status.message)) : res
      }),
      catchError(error => {
        errorEmitter(error);
        return of<T>(defaultResponse)
      })
    )

